import { Container } from "@mui/material";
import React from "react";

const white = "rgb(255, 255, 255)";

export default function Privacy() {
  return (
    <Container maxWidth="md" sx={{}}>
      <div style={{ height: "150px" }} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h1
          style={{
            textAlign: "left",
            marginTop: "0cm",
            fontSize: "24px",
            fontWeight: "bold",
            color: white,
            fontSize: "28px",
          }}
        >
          Privacy Policy
        </h1>
        <p style={{ textAlign: "left", color: white }}>
          <strong>Company:</strong> Metastream s.r.o.
        </p>
        <p style={{ textAlign: "left", color: white }}>
          <strong>IČO:</strong> 08393494
        </p>
        <p style={{ textAlign: "left", color: white }}>
          <strong>Address:</strong> Metastream s.r.o., Brno, Högrova 2862/2
        </p>
        <p style={{ textAlign: "left", color: white }}>
          This Privacy Policy describes how Metastream s.r.o. ("Company")
          collects, uses and shares your personal information. This Policy
          applies to all mobile applications operated by the Company (the
          "Service").
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>The information we collect</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            The Application may collect the following types of personal
            information:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Information you provide to us:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;This is information that you voluntarily provide to us when
              you use the Application, such as your name, email address, phone
              number, profile picture, and other information.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Information about your device:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;the App may collect information about your device, such as
              its type, operating system, device ID, and IP address.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Information about your use of the App:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;the App may collect information about how you use the App,
              such as which features you use, how often you use the App, and
              what data you interact with on the App.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>How we use your personal information</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            We may use your personal information for the following purposes:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>To provide you with the services of the App</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;we use your personal information to provide you with the
              features and services of the App.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Improving the App:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;we use your personal information to improve the features and
              services of the App and to provide you with a more relevant and
              personalized experience.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Communicating with you:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;we use your personal information to communicate with you
              about the App, including news, updates and customer support.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>For advertising purposes:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;We may use your personal information to serve you ads that
              are more relevant to you.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Sharing your personal information</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            We may share your personal information with the following entities:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Service Providers:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;we may share your personal information with third parties
              who help us operate the App, such as cloud service providers and
              analytics companies.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Supervisory Authorities:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;We may share your personal information with supervisory
              authorities when required to do so by law.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Your rights</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            You have the following rights regarding your personal information:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Access to your personal information:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;You have the right to access the personal information we
              collect about you.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Correction of your personal data:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;you have the right to have your personal data corrected if
              it is incorrect or incomplete.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Erasure of your personal data:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;you have the right to request the erasure of your personal
              data in certain cases.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>
                Restriction of the processing of your personal data:
              </strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;you have the right to restrict the processing of your
              personal data in certain cases.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Object to the processing of your personal data:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;you have the right to object to the processing of your
              personal data in certain cases.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Opt out of marketing communications</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            You can unsubscribe from receiving marketing communications from us
            at any time. You can do this by clicking on the "unsubscribe" link
            at the bottom of the marketing email or in the App settings.
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Data sharing with Apple and Google</strong>
          </span>
        </p>
        <p style={{ textAlign: "left", fontSize: "20px" }}>
          <span style={{ color: white }}>
            <strong>Apple:</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            Apps use certain Apple services that may collect and share your
            data. These include:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Apple App Store:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Apple collects information about your device and how you use
              the App in order to provide you with App Store services.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Apple iCloud:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;If you use iCloud to back up your App data, your data will
              be shared with Apple.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Apple Push Notifications:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Apple collects information about your device and how you use
              the App to provide you with push notifications.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left", fontSize: "20px" }}>
          <span style={{ color: white }}>
            <strong>Google:</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            The App uses certain Google services that may collect and share your
            data. These include:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Google Play Store:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Google collects information about your device and how you
              use the App in order to provide you with Google Play services.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Google Firebase:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Google collects information about your device and how you
              use the App in order to provide you with Firebase features and
              services.
            </span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Google Analytics:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Google collects information about your device and how you
              use the App in order to analyse your use of the App and improve
              its features.
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Additional Information:</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            For more information about how Apple and Google collect and use your
            data, please see their privacy policies:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Apple:</strong>
            </span>
            <span style={{ color: white }}>&nbsp;</span>
            <a href="https://www.apple.com/privacy/" target="_blank">
              <span style={{ color: "rgb(0, 0, 255)" }}>
                <u>https://www.apple.com/privacy/</u>
              </span>
            </a>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Google:</strong>
            </span>
            <span style={{ color: white }}>&nbsp;</span>
            <a href="https://policies.google.com/privacy" target="_blank">
              <span style={{ color: "rgb(0, 0, 255)" }}>
                <u>https://policies.google.com/privacy</u>
              </span>
            </a>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Important:</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            Apple and Google may use your data for their own purposes not
            described in this Policy. Please read Apple's and Google's privacy
            policies before using the App.
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white, fontSize: "24px" }}>
            <strong>Contact Information:</strong>
          </span>
        </p>
        <p style={{ textAlign: "left" }}>
          <span style={{ color: white }}>
            If you have any questions about this Privacy Policy, please feel
            free to contact us:
          </span>
        </p>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>E-mail:</strong>
            </span>
            <span style={{ color: white }}>&nbsp;info@metastream.cz</span>
          </p>

          <p style={{ textAlign: "left" }}>
            <span style={{ color: white }}>
              <strong>Address:</strong>
            </span>
            <span style={{ color: white }}>
              &nbsp;Metastream s.r.o., Brno, Högrova 2862/2
            </span>
          </p>
        </div>
        <p style={{ textAlign: "left" }}>
          <br />
          &nbsp;
        </p>
        <p style={{ textAlign: "left" }}>
          <strong>Effective date:</strong> 2. února 2022
        </p>
        <p
          style={{
            textAlign: "left",
            marginLeft: "0.64cm",
          }}
        >
          <br />
        </p>
      </div>
    </Container>
  );
}
